.mailchimp-custom > input{
  margin-bottom: 10px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;

  /* Round Borders*/
  border-width: 1px;
  border-radius: 30px !important;
  padding: 11px 23px;
}

.mailchimp-custom > button {
  display: inline-block;
  text-align: center!important;

  color: #2CA8FF;
  font-size: 1em;
  border-radius: 0.25rem;
  padding: 15px 48px;

  /* Round Borders*/
  border-width: 1px;
  border-radius: 30px !important;
  padding: 11px 23px;

  margin: 40px;
}

.msg-alert {
  color: white !important;
}

.landing-page .section-team .team-player {
  margin-bottom: 80px;
}

.landing-page .section-team .team .team-player img {
    max-width: 185px !important;
}

.because-image {
  margin-top: 70px;
  margin-left: 90px;
  max-width: 450px;
}

.fingertips {
  margin-left:80px;
  max-width: 450px;
}

.how-it-works-steps {
  align: "left" !important;
}

.value-props {
  max-width: 90px !important;
  margin-top: 20px;
}

.value-props-description {
  line-height: 1.61em;
  font-size: 1.0em;
}

@media (max-width: 870px) {
  .brand .h1-seo  {
    font-size: 3em !important;
  }

  .because-image {
    max-width: 200px;
  }

  .fingertips {
    max-width: 200px;
  }
}
